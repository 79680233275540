import React from 'react'
import SEO from "../components/seo"
import LayoutsComponent from '../layouts/LayoutsComponent';
import VerificacionCredencial from '../components/pages/afi/VerificacionIndentidad';

class VerifocacionCredencialPage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { pageContext, location } = this.props
        return (
            <LayoutsComponent
                layout={pageContext.layout}
                broker={pageContext.broker}
                companyName={pageContext.company}
                pageContext={pageContext}
                location={location}
                render={() => (
                    <>
                        <SEO title={pageContext.title} />
                        <VerificacionCredencial pageContext={pageContext} />
                    </>
                )}
            />
        )
    }
}

export default VerifocacionCredencialPage;
